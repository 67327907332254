import React from 'react'
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";


const SortIcon = ({ select, sort }) => {
    // console.log('sortIcon', select, sort)
    return (
        <span className="sort">
            <TiArrowSortedUp style={{ color: `${select == null ? `rgb(123, 123, 123)` : `${select === sort ? `black` : `rgb(123, 123, 123)`}`}`, fontSize: '18px', marginBottom: "-5px" }} />
            <TiArrowSortedDown style={{ color: `${select == null ? `rgb(123, 123, 123)` : `${select === sort ? `rgb(123, 123, 123)` : `black`}`}`, fontSize: '18px', marginTop: "-5px" }} />
        </span>
    )
}

export default SortIcon;