import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  user: null,
  token: null
}

export const authslice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      localStorage.setItem("auth", JSON.stringify({
        user: action.payload.user,
        userId: action.payload.userId,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        expiry: action.payload.expiry,
      }));

      state.user = action.payload.user
      state.userId = action.payload.userId
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.expiry = action.payload.expiry
    },

    logout: (state) => {
      localStorage.clear();
      state.user = null
      state.userId = null
      state.token = null
      state.refreshToken = null
      state.expiry = null
    },
    processToken: (state) => {
      const authInfo = JSON.parse(localStorage.getItem('auth'))
      if (authInfo) {
        state.user = authInfo.user
        state.userId = authInfo.userId
        state.token = authInfo.token
        state.refreshToken = authInfo.refreshToken
        state.expiry = authInfo.expiry
      }
    }

  },
})

// Action creators are generated for each case reducer function
export const { setToken, processToken, logout } = authslice.actions

export default authslice.reducer