import React from 'react'

const ViewRow = () => {
    return (
        <>
            <tr className='bg-gray'>
                <td></td>
                <td></td>
                <td>870Y</td>
                <td></td>
                <td></td>
                <td></td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr className='bg-gray'>
                <td></td>
                <td></td>
                <td>870Y</td>
                <td></td>
                <td></td>
                <td></td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr className='bg-gray'>
                <td></td>
                <td></td>
                <td>870Y</td>
                <td></td>
                <td></td>
                <td></td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr className='bg-gray'>
                <td></td>
                <td></td>
                <td>870Y</td>
                <td></td>
                <td></td>
                <td></td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td>##</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </>
    )
}

export default ViewRow