/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import moment from "moment";
import Swal from 'sweetalert2';
import { parseCookies, setCookie, destroyCookie } from "nookies";
import cookie from "js-cookie";
import { baseUrl, API_URL } from '../../config/config';

const MultipleHorses = ({ show, handleClose, handleHistory, handleView }) => {

    if (!show) return;

    return (
        <div className='modal__wrapper' style={{alignItems: 'center'}}>
            <div className='modal__card file_upload_card' style={{marginTop: '0 !important'}}>

                <div className='modal__head text-start'>
                    <h3>File upload</h3>
                    <span onClick={() => handleClose(false)}>
                        <i className="fas fa-times"></i>
                    </span>
                </div>

                <div className='row mt-3 modal__form text-start'>
                    <div className='col-md-12 mb-4'>
                        <h4 className='d-block justify-content-between' style={{ color: '#0AA752' }}>
                            <img src='/assets/img/icon/check.svg' style={{
                                width: '24px',
                                height: '24px',
                                marginTop: '-3px',
                                marginRight: '10px',
                            }} />
                            File uploaded successfully.
                        </h4>
                    </div>
                    
                    <div className='col-md-12 d-flex gap-2'>
                        <button onClick={() => handleClose(false)} className='btn btn-default border site-s-font'>Breed Again</button>
                        <button onClick={handleHistory} className='btn btn-default border site-s-font'>History</button>
                        <button className='btn btn-primary site-s-font'>View Results</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultipleHorses;