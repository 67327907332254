/* eslint-disable eqeqeq */
import React from 'react'
import { LiaTimesCircleSolid } from "react-icons/lia";

const MetaModal = ({ showAddMeta, onCloseMeta, handleShowAddMeta,  setIsAddData, horses, eventInfo }) => {

    const handeAddData = () => {
        setIsAddData(1)
    }

    if (!showAddMeta) return

    return (
        <div className='modal__wrapper'>
            <div className='modal__card_small' style={{ width: '450px' }}>
                <div className='promt__area'>
                    <div className='prompt__head'>
                        <span>
                            <LiaTimesCircleSolid className="text-danger" style={{ fontSize: '26px' }}/>
                        </span>
                        <span style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '24px',
                        }}
                        >Finish Meta Data</span>
                    </div>

                    <div>
                        <div className='row'>
                            <div className='col-md-1'></div>
                            <div className='col-md-11'>
                                <p className='prompt__pera text-start'>
                                    There {horses.length == 1 ? "is" : "are"} {horses.length} {horses.length == 1 ? "horse" : "horses"} with required meta data <br /> that are missing. You can't add your <br /> current result to an event until corrected.
                                </p>
                            </div>

                            <div className='col-md-12 d-flex justify-content-end'>
                                <div className='col-md-12 d-flex justify-content-end'>
                                    <button className='btn btn-defaut border site-s-font mx-3' onClick={() => handleShowAddMeta(false)}>Cancel</button>
                                    <button className='btn btn-primary site-s-font' onClick={handeAddData}>Add Data</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MetaModal    