import React from 'react'
import { NavLink } from 'react-router-dom'
const Nav = () => {
  return (
    <>
        <div className='nav__middle nav__menu gap-3'>
            <NavLink to="/breed-horses" 
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""} style={{
              
            }}>Breed Horses</NavLink>
            
            <NavLink to="/bred-horses"
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""}
            >Bred horses</NavLink>

            <NavLink to="/events"
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""}
            >Events</NavLink>

            {/* <NavLink to="/favourites"
            className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""}
            >ALL Horses</NavLink> */}
        </div>
    </>
  )
}

export default Nav