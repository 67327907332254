/* eslint-disable eqeqeq */
import React from 'react'

const PromtModal = ({ showAdd, onClose, setIsMeta, handleAddEvent, horses, eventInfo }) => {

    if (!showAdd) return

    return (
        <div className='modal__wrapper'>
            <div className='modal__card_small'>
                <div className='promt__area'>
                    <div className='prompt__head'>
                        <span><i className="fas fa-exclamation-circle text-warning"></i></span>
                        <h4>Are You Sure?</h4>
                    </div>

                    <div>
                        <div className='row'>
                            <div className='col-md-1'></div>
                            <div className='col-md-11'>
                                <p className='prompt__pera text-start'>
                                    Are you sure to add “{horses.length}” {horses.length == 1 ? "horse" : "horses"} to the <br />“{eventInfo.name}”
                                </p>
                            </div>
                            <div className='col-md-12 d-flex justify-content-end'>
                                <button className='btn btn-defaut border site-s-font mx-3' onClick={onClose}>Cancel</button>
                                <button className='btn btn-primary site-s-font' onClick={() => handleAddEvent(eventInfo.id, eventInfo.name)}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromtModal