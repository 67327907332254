/* eslint-disable no-unused-vars */
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { baseUrl, API_URL } from '../../config/config'

const ShareModal = ({ showShare, id, onClose }) => {
  if (!showShare) return

  return (
    <div
      className="modal fade show"
      id="exampleModalCenter"
      tabIndex={-1}
      aria-labelledby="exampleModalCenterTitle"
      style={{ display: "block" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header p-3">
            <h1 style={{ fontSize: '20px', fontWeight: '600' }} >Share Event</h1>
            <i className="fa fa-times" aria-hidden="true" style={{ width: '8.66px', height: '8.91px', marginRight: '15px', cursor: 'pointer' }} onClick={onClose}></i>

          </div>
          <div className="modal-body d-flex align-items-center p-2" style={{ height: '70px' }}>
            <a href={`${baseUrl}/share/${id}`} className='mx-3 text-dark' style={{width: '80%', fontSize: '13px', fontWeight: '400'}}>{`${baseUrl}/share/${id}`}</a>
            <CopyToClipboard text={`${baseUrl}/share/${id}`}  style={{width: '20%', marginRight: '3px'}}>
              <button onClick={onClose} className='btn btn-default border px-3 text-primary d-flex align-items-center justify-content-between' style={{ width: '91px', height: '32px' }}>
                <i className="far fa-copy"></i>
                <span style={{ fontSize: '14px', fontWeight: '400' }}>Copy</span>
              </button>
            </CopyToClipboard>
          </div>
          <div className="modal-footer">
            <button className='btn btn-defaut border site-s-font mx-3' style={{ width: '78px', height: '32px' }} onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareModal